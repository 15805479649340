/*
Gallery Styling
 */

.gallery-container {
  padding: 50px 30px 50px 30px;

  h1 {
    font-size: 3.4rem;
    line-height: $line-height;
  }

  p {
    font-size: 1.3rem;
    font-family: NewYorkRegular, sans-serif;
    line-height: $line-height;
  }

}

.gallery-grids {
  margin-top: 100px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(4, 500px);

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 990px) {
  .gallery-grids {
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(6, 500px);
  }
}
