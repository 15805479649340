/*
Footer styling
 */

.footer-container{
  height: 50vh;
  .site-menu {
    display: grid;
    grid-template-columns: repeat(8, auto);

    li {
      cursor: pointer;
      font-size: 1rem;
      list-style-type: none;
      margin: 0px 10px 50px 10px;
    }

  }

  .content {
    padding: 25px 10px 25px 10px;
    p {
      font-size: 1.4rem;
    }
  }

  .logo-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .logo {
      min-width: 100px;
      min-height: 100px;
    }
  }

}

hr {
  padding-left: 25px;
  padding-right: 25px;
}

@media (max-width: 990px) {
  .footer-container{
    .site-menu{
      display: grid;
      grid-template-columns: repeat(4, auto);
    }
  }

  .content {
    text-align: center;
    p {
      text-align: left;
      font-size: 1rem !important;
    }
  }
  .logo-container {
    .logo {
      width: 50px;
    }
  }
}
