/*
Homepage content and styling
 */

/*
Styling for Intro Grid Container
 */

.intro-grid-container {
  margin-top: 0px; //gives it a space over the container
  display: grid;
  grid-template-columns: 1fr 1fr; //two columns that have 50% division of space
  grid-template-rows: repeat(1, 800px); //creates 4 rows of 800px each
  align-content: center;

  .grid-item{
    padding: 50px; // for clean look
    align-self: center; // for aligning vertically middle
    text-align: left; // for the text to align left as per english reading
    p {
      font-size: 3rem;
      line-height: $line-height;
    }
  }

  .grid-item-image {
    background: antiquewhite;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.intro-grid-container-left{
  @extend .intro-grid-container;
  grid-template-columns: 1fr 1fr; //two columns that have 50% division of space
}

.intro-grid-container-right{
  @extend .intro-grid-container;
  grid-template-columns: 1fr 1fr; //two columns that have 50% division of space
}



/*
Styling for large CTA container
 */

.large-cta-container {
  height: 80vh;
  padding: 50px;

  .inner-cta-container {
    height: 100%;
    background: #fffbcc;

    .inner-content {
      width: 70%;
      text-align: left;
      padding: 100px;

      h1 {
        font-size: 3.4rem;
        line-height: $line-height;
      }

      p {
        padding-top: 50px;
        font-size: 1.5rem;
        text-decoration: underline;
      }
    }

  }


}

/*
image grid container
 */

@mixin image-action-grid-container {
  margin: 10px 0 10px 0;
  min-height: 30vh;
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-rows: repeat(1, 70vh);

  .grid-action{
    position: relative;
    display: grid;
    text-align: left;
    background: #000000;

    .grid-action-content {
      position: absolute;
      top: 20%;
      padding: 50px;

      h1{
        color: #fff;
      }

      p {
        color: #fff;
        font-size: 1.5rem;
      }
    }

  }
  .grid-picture{
    background: antiquewhite;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.image-action-grid-container-left {
  @include image-action-grid-container;
  grid-template-columns: 25% 75%;
  grid-template-rows: repeat(1, 70vh);
  .grid-action{
    margin-right: 10px;
  }
}

.image-action-grid-container-right {
  @include image-action-grid-container;
  grid-template-columns: 75% 25%;
  grid-template-rows: repeat(1, 70vh);
  .grid-action{
    margin-left: 10px;
  }
}

/*
====================================================================
MOBILE
====================================================================
 */


@media (max-width: 990px) {
  .intro-grid-container {
    grid-template-rows: repeat(1, 350px); //creates 4 rows of 350px each
    .grid-item {
      padding: 25px;
      p {
        z-index: 2;
        font-size: 1.2rem;
      }
    }
  }


  .image-action-grid-container-left {
    grid-template-columns: 75% 25%;
    grid-template-rows: repeat(1, 70vh);
    .grid-action{
      margin-right: 10px;
    }
  }


  .image-action-grid-container-right {
    grid-template-columns: 25% 75%;
    grid-template-rows: repeat(1, 70vh);

    .grid-action{
      margin-left: 10px;
    }

  }

    .large-cta-container {
      height: inherit;
    padding: 10px;

    .inner-cta-container {

      .inner-content {
        padding: 100px 30px 100px 30px;
        width: 100%;

        h1 {
          font-size: 1.8rem;
          line-height: $line-height;
        }

        p {
          padding-top: 50px;
          font-size: 1.5rem;
          text-decoration: underline;
        }

      }
    }

  }
}