/*
For components like headings, buttons, decorative elements
 */

.booking-cta-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80vh;

  .booking-cta-inner {
    width: 60%;

    h1 {
      font-size: 2.5rem;
    }

    button {
      margin-top: 25px;
      font-size: 1.4rem;
      outline: none;
      border: none;
      background: black;
      color: #fff;
      padding: 10px 20px 10px 20px
    }

  }

}