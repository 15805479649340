// variables for the project.


@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/SF-Pro-Display-Semibold.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'NewYork';
  src: url('../assets/fonts/NewYorkLarge-Medium.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'NewYorkRegular';
  src: url('../assets/fonts/NewYorkMedium-Regular.otf'); /* IE9 Compat Modes */
}


@font-face {
  font-family: 'SFProLight';
  src: url('../assets/fonts/SF-Pro-Display-Light.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'SFProRegular';
  src: url('../assets/fonts/SF-Pro-Display-Regular.otf'); /* IE9 Compat Modes */
}


$primary-color: #000;
$line-height: 1.5;
