/*
Contact Page Styling
 */


.contact-container {
  padding: 50px 30px 50px 30px;

  h1 {
    font-size: 3.4rem;
    line-height: $line-height;
  }

  p {
    font-size: 1.3rem;
    font-family: NewYorkRegular, sans-serif;
    line-height: $line-height;
  }
}