/*
Services page styling
 */

.incub8 {
  font-family: SFProDisplay, sans-serif;
  letter-spacing: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}

.services-container {
  padding: 50px 30px 50px 30px;

  h1 {
    font-size: 3.4rem;
    line-height: $line-height;
  }

  p {
    font-size: 1.3rem;
    font-family: NewYorkRegular, sans-serif;
    line-height: $line-height;
  }

  .service-title {
    padding: 50px 0 50px 0;
  }

  .service-list-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    li {
      text-decoration: underline;
      list-style-type: none;
      padding:40px 40px 40px 0;
      font-family: NewYorkRegular, sans-serif;
      font-size: 1rem;
    }
  }

}

.image-row-container {
  overflow-x: hidden;
  margin: 100px 0px 100px 0px;
  display: grid;
  grid-template-columns: repeat(4, 30%);
  grid-template-rows: repeat(1, 700px);

  .image-item {
    background: antiquewhite;
    margin-right: 10px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

  }
}

/*
====================================================================
MOBILE
====================================================================
 */


@media (max-width: 990px) {
  .service-list-container {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }

  .image-row-container {

    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(1, 400px);

    .image-item {
      margin-bottom: 10px;
      background: antiquewhite;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

    }
  }
}