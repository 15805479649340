/*
Pricing Styling
 */

.pricing-container {
  padding: 50px 30px 50px 30px;

  h1 {
    font-size: 3.4rem;
    line-height: $line-height;
  }

  p {
    font-size: 1.3rem;
    font-family: NewYorkRegular, sans-serif;
    line-height: $line-height;
  }

  .pricing-column-inner {
    margin-top: 100px;
    padding: 100px;
    background: black;
    color: #ffffff;

    .white-border {
      border-bottom: 1.5px solid #fff;
    }

    li {
      list-style-type: none;
      font-family: NewYorkRegular, serif;
      text-decoration: underline;
      font-size: 1.2rem;
      padding-top: 10px;
    }

  }

  .orange {
    background: #f17808 !important;
  }

  .green {
    background: #2fc4b2 !important;
  }

  .pricing-button {
    width: 100%;
    padding: 30px;
    font-size: 1.5rem;
    background: black;
    color: #ffffff;
  }

}


@media (max-width: 990px) {
  .pricing-container {

    h1, p {
      padding-left: 30px;
      padding-right: 30px;
    }

    padding: 50px 0px 50px 0px;
    .pricing-column-inner {
      padding: 50px;
    }
  }
}
