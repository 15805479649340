/*
Styling for the header
 */

.header-container {
  margin-top: 20px;
  padding: 10px;

  .logo-container {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    img {
      height: 80px;
    }

    p {
      font-family: SFProDisplay, sans-serif;
      text-align: left;
      padding: 15px;
      font-size: 1.5rem;
      letter-spacing: 10px;
      font-weight: bold;
      position: relative;

      &:after {
        top: 80%;
        left: 20%;
        position: absolute;
        font-size: 0.8rem;
        letter-spacing: 1px;
        content: 'by Powai Cubicles'
      }

    }
  }

  .menu-container {
    .menu{
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      li {
        @extend .hvr-underline-from-center;
        font-family: NewYorkRegular, sans-serif;
        cursor: pointer;
        padding: 10px;
        border: #000 !important;
        letter-spacing: 1px;
        position: relative;
        list-style-type: none;
      }

    }
  }

  .call-to-action-container{
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    p {
      font-family: SFProRegular, sans-serif;
      cursor: pointer;
      letter-spacing: 1px;
    }
  }

}


.mobile-cta-container {
  position: fixed;
  z-index: 100;
  bottom: 5px;
  right: 5px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
}

@media (max-width: 990px) {
  .header-container {
    .menu-container{
      padding: 25px 0 25px 0;
    }

    .call-to-action-container {
      display: none;
    }
  }
}


